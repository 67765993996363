/* footer */
footer {
  background-color: #a98467;
  padding: 1rem 5rem;
  color: white;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-logo {
  font-size: 30px;
  font-weight: 500;
  font-family: "Bad Script", cursive;
  width: fit-content;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-right: 30rem;
  font-family: "EB Garamond", serif;
}

.footer-link-item {
  margin-right: 1.2rem;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-media-item {
  margin-right: 1rem;
}

.copy-right {
  width: fit-content;
  margin: 2rem auto 1rem;
  font-family: "EB Garamond", serif;
}

@media (max-width: 600px) {
  footer {
    padding: 1rem 3rem;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-links {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-link-item {
    margin: 1rem 0;
  }
  .social-media-item {
    margin-top: 1rem;
    margin-right: 2rem;
  }
}
